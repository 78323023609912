import { React, useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";

const CompanySelectionModal = (props) => {
  const [error, setError] = useState("");
console.log(" companydata ", sessionStorage.getItem("CompanyData"))

let companydata=sessionStorage.getItem("CompanyData").split(",");

  const [selectedCompany, setSelectedCompany]=useState("");

  const handleChange = (e) => {
    setSelectedCompany(e.target.value)
};
const handleSubmit = () => {
 if(selectedCompany){
  console.log("InSelctedCompany", selectedCompany)
  props.handleSubmit(selectedCompany)
 }
 else{ setError("Please select Company ")}
};

  return (
    <>
     
      <Modal
        className="companyalertmodal"
        show={props.show}
        onHide={props.handleClose}
        animation={false}
        backdrop="static"
      >
        <br></br>
        <Modal.Body className="confirmcss">
          <div className="login-header">Select Logistic Company </div>
          
          <Row>
          {error && <div className="text-label-with-error">{error}</div>}
            {companydata && companydata.length > 0 && (companydata?.map((item, index) => (
              <Col md={3} xs={12} key={item} className="txt-input">
                <Form.Check
                  type="radio"
                  name="selectedCompany"
                  checked={selectedCompany === item}
                  onChange={handleChange}
                  label={item}
                  value={item}
                ></Form.Check>
              </Col>
            )))}
          </Row>
      </Modal.Body>
      <Modal.Footer>
     
                            <Button className="btn semibold-togglebutton right-alignement btn-color" type="submit" onClick={() =>handleSubmit()}>
                            Submit
                            </Button>
                            <Button className="btn btn-outline-primary reset-button right-alignement mx-3"  onClick={() => props.handleClose()} variant="outline-primary">Cancel</Button>
                       
      </Modal.Footer>
    </Modal >
    </>
  );
};

export default CompanySelectionModal;
