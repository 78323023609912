import React from "react";
import { Navbar, Row, Image, OverlayTrigger, Popover } from "react-bootstrap";
import { List } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { userlogo } from "../../helpers/formatter";
import constants from "../../helpers/constants";
import logoutarrow from "../../assets/logoutarrow.svg"
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Header(props) {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const location = useLocation();

  const menuNames = {
    "/": "Dashboard",
    "/usermaster": "Maintain Users",
    "/roleauthorization": "Role Master",
    "/itemmaster": "Item Code Master",
    "/projectmaster": "Project Master",
    "/projectcosttype": "Project Cost Type Master",
    "/currencyexchangerate": "Currency Exchange Rate",
    "/productcost": "Product Cost Master",
    "/sodetails": "So Details Master",
    "/sosummary": "So Summary Master",
    "/workflowmatrix": "Workflow Matrix Master",
    "/businesscode": "Business Code Master",
    "/simulatepoc": "Simulate POC",
    "/estimatedcost": "Maintain Project Estimated Cost",
    "/actualprojectcost": "Maintain Actual Project Cost",
    "/jvgeneration": "JV Generation",
    "/quarterlyestimate": "Quarterly Estimate Confirmation"
  };

  const MenuName = menuNames[location.pathname] || "";

  const handleLogout = (instance) => {
    navigate('/');
    sessionStorage.clear();
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  const handleSubmit = async () => {
    const confirmationMessage = "Are you sure you want to Logout ?";
    const title = "Logout";

    MySwal.fire({
      title: title,
      html: confirmationMessage,
      ...constants.CONFIRMATION_BOX_CONFIG
    }).then(async (selection) => {
      if (selection.isConfirmed) {
        handleLogout(instance);
      }
    });
  };

  return (
    <Navbar bsPrefix="header">
      <div className="sidebar-head-header">
        <List className="head-toggle" onClick={() => { props.setSidebar(props.sidebar === "closed" ? "open" : "closed") }}></List>
      </div>
      <div className="app-name">{MenuName}</div>
      <div className="user-section">
        <OverlayTrigger trigger="click" placement="bottom-end" rootClose overlay={
          <Popover className="profile" aria-labelledby="contained-modal-title-vcenter">
            <div className="user-section">
              <div className="user-logo">
                {userlogo(sessionStorage.getItem("UserId"))}
              </div>
              <div className="user-info">
                <Row>
                  <span className="user-name">{sessionStorage.getItem("UserId")}</span>
                </Row>
                <Row>
                  <span className="user-email">{sessionStorage.getItem("Email")}</span>
                </Row>
              </div>
              <Image src={logoutarrow} onClick={handleSubmit}></Image>
            </div>
          </Popover>
        }>
          <div className="user-logo">
            {userlogo(sessionStorage.getItem("UserId"))}
          </div>
        </OverlayTrigger>
      </div>
    </Navbar>
  );
}
